import { AutoComplete, Input, Spin, Divider, Collapse, Card, Modal, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { IEntity, ITriple, ITripleElement, SingleProp, URI } from 'agora-types';
import EntityFeedItem from '../EntityFeedItem/EntitiyFeedItem'

interface EntityFeedProps {
    loading: boolean
    entities: IEntity[]
    editTriple: (trip: ITriple) => void
    removeEntity: (subject: URI) => void
    removeTriple: (trip: ITriple) => void
    addTriple: (subject: URI, classLabel: string) => void
}

const EntityFeed: React.FC<EntityFeedProps> = (props) => {

    const { loading, entities, editTriple, removeEntity, removeTriple, addTriple } = props

    if (loading) {
        return (<div style={{textAlign: 'center'}}> <Spin size="large"/> </div>)
    } else {
        return (
            <div>
                {entities.map((entity: IEntity) => { 

                    let instanceOfTrip: ITriple = entity.statements.find((trip : ITriple) => trip.predicate.value === 'https://agora-data.com/Property/instanceOf')
                    let classLabel: string = instanceOfTrip.object.value.split('/').reverse()[0]
                    let subjectURI: URI = instanceOfTrip.subject
                    
                    return(
                        <div key={subjectURI.value}> 
                            <EntityFeedItem 
                                onEditTriple={(trip: ITriple) => editTriple(trip)}
                                onDelete={() => removeEntity(entity.statements[0].subject)}
                                onDeleteTriple={(trip: ITriple) => removeTriple(trip)} 
                                onAdd={() => addTriple(subjectURI, classLabel)} 
                                entity={entity}/>
                            <Divider/>
                        </div>
                    )
                }
            )}
            </div>
        );
    }
};

export default EntityFeed
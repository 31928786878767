import { Button, Form, Divider, Card, Tag, Row, Col } from 'antd';
import { ITripleElement, MultiProp, ITriple, URI, SingleProp } from 'agora-types';
import React from 'react';
import PropertyEditor from 'components/PropertyEditor/PropertyEditor';
import TripleInput from 'components/TripleInput/TripleInput';
import UriSelect from 'components/UriSelect/UriSelect';
import { toTitleCase } from 'utils/stringUtils';

interface MultiPropEditorProps {
    triples: { [pid: string] : { [tid: string] : ITriple } }
    prop: MultiProp
    error: string
    onChange: (tid: string, predicate: URI, object: ITripleElement) => void
    onAdd: (prop: SingleProp) => void
    onDelete: (pid: string, tid: string) => void
    onFinishEdit?: (tid: string) => void
}

export default function MultiPropEditor(props: MultiPropEditorProps): JSX.Element {

    const { triples, prop, onChange, onDelete, onAdd, onFinishEdit, error } = props
    
    let uris = Object.keys(prop.options).reduce((map: any, pid: string) => {
        map[pid] = {
            label: toTitleCase(prop.options[pid].label),
            value: prop.options[pid]
        };
        return map;
    }, {});

    let defaultAdd : SingleProp = Object.values(prop.options)[0]

    return ( <Card 
        title={!prop.unique ? <Button onClick={() => onAdd(defaultAdd)}> Add + </Button> : null}
    > 
        {Object.keys(triples).map((pid: string) => {
            let sp: SingleProp = prop.options[pid]
            return Object.keys(triples[pid]).map((tid: string, i: number) => 
            <div key={pid + tid}>
                <Row gutter={8}>
                    <Col span={6}>
                        <UriSelect uri={pid} uris={uris} onSelect={(value: any) => {
                            let sp: SingleProp = value as SingleProp
                            onDelete(pid, tid)
                            onAdd(sp)
                        }}/>
                    </Col>
                    <Col span={18}>
                        <TripleInput 
                            value={triples[pid][tid].object}
                            onFinishEdit={() => props.onFinishEdit(tid)}
                            kind='insert'
                            error={props.error}
                            prop={sp}
                            typeRange={sp.dataTypeRange.map((uri: URI): { 
                                kind: 'entity' | 'literal', 
                                value: URI
                            } => {
                                return {
                                    value: uri,
                                    kind: 'literal'
                                }
                            }).concat(sp.entityRange.map((uri: URI): { 
                                kind: 'entity' | 'literal', 
                                value: URI
                            } => {
                                return {
                                    kind: 'entity',
                                    value: uri
                                }
                            }))}
                            removable={!prop.required || i !== 0}
                            onDelete={() => onDelete(sp.URI, tid)}
                            onChange={(predicate: URI, object: ITripleElement) => onChange(tid, predicate, object)}/>
                    </Col>

                </Row>
                <Divider />
            </div>)        
        }
    )}
    </Card>   
            // <Card
            //     style={{width: '100%'}}
            //     title={!prop.unique ? <Button onClick={() => onAdd({ value: prop.URI, label: prop.label, kind: 'uri' })}> Add + </Button> : null}>
            //     <div>
            //         <div>
            //             {Object.keys(triples).map((tid: string, i: number) => 
            //             <div key={i + 100}>
            //                     <TripleInput
                                    // defaultValue={triples[tid].object}
                                    // onFinishEdit={() => props.onFinishEdit(tid)}
                                    // kind={props.kind}
                                    // error={props.error}
                                    // prop={prop}
                                    // typeRange={typeRange}
                                    // removable={!prop.required || i !== 0}
                                    // onDelete={() => onDelete(tid)}
                                    // onChange={(predicate: URI, object: ITripleElement) => onChange(tid, predicate, object)}/>
            //                     <Divider />
            //             </div>
            //             )}
            //         </div>
            //     </div>
            // </Card>
    )
}
import { Button, Modal } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { setAddModal, submitNewTriple } from './insertTripleSlice';
import InsertTriple from './InsertTriple';


const InsertTripleModal: React.FC = () => {

    const dispatch = useDispatch()
    const { addModal, addLoading, newTriple } = useSelector((state: RootState) => state.insertTripleReducer)
    
    return <div>
        <Modal
            title="Add Triple"
            width="70%"
            footer={[
                <Button key="back" onClick={() => dispatch(setAddModal(false))}>
                Cancel
                </Button>,
                <Button key="submit" loading={addLoading} type="primary" onClick={() => dispatch(submitNewTriple(newTriple))}>
                Submit
                </Button>
            ]}
            visible={addModal}
            onOk={() => dispatch(setAddModal(false))}
            onCancel={() => dispatch(setAddModal(false))}
        >
            <InsertTriple />
        </Modal>
    </div>
};

export default InsertTripleModal
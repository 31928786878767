import {  Spin, Divider, Collapse } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import ObjectInput from 'components/ObjectInput/ObjectInput';
import { ITripleElement } from 'agora-types';
import { changeUpdatedTriple } from './updateSlice';

const UpdateTriple: React.FC = () => {

    const dispatch = useDispatch()
    const { updateModal, updateError, updateLoading, oldTriple, updatedTriple } = useSelector((state: RootState) => state.updateReducer)

    return (<div> 
            {updatedTriple ? <div> 
                        
            Old Value: 
            <ObjectInput 
                disabled={true} value={oldTriple.object} 
                // dataType={oldTriple.object.kind === 'literal' ? 
                //     oldTriple.object.dataType : oldTriple.object.kind === 'uri' ? oldTriple.object : null} 
                error={''} onChange={console.log}/>

            <Divider />

            New Value: 
            <ObjectInput 
                value={updatedTriple ? updatedTriple.object : null} error={updateError} 
                // dataType={updatedTriple.object.kind === 'literal' ? 
                //     updatedTriple.object.dataType : updatedTriple.object.kind === 'uri' ? updatedTriple.object : null} 
                onChange={(obj: ITripleElement) => dispatch(changeUpdatedTriple(obj))}/>
            </div> : null} 
        </div>)
};

export default UpdateTriple
import { Select } from 'antd';
import { URI } from 'agora-types';
import React from 'react';

interface TypeSelectProps {
    uris: { [uri: string] : {
        label: string,
        value: any
    }}
    uri: string
    onSelect: (value: any) => void
}
const { Option } = Select;

export default function UriSelect(props: TypeSelectProps): JSX.Element {
    
    return (
        <Select
            style={{width: '100%'}}
            value={props.uris[props.uri] ? props.uris[props.uri].label : ''}
            onSelect={(uri: string, option: any) => {
            props.onSelect(props.uris[uri].value)
        }}>
            {Object.keys(props.uris).map((key: string) => {
                return <Option key={key} value={key}> {props.uris[key].label} </Option>
            })}
        </Select>)
}
import {  Spin, Divider, Collapse } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import UriSelect from '../../../components/UriSelect/UriSelect';
import { IClass, URI, SingleProp, ITripleElement } from 'agora-types';
import EntityInput from 'components/EntityEditor/EntityEditor';
import TripleElement from 'agora-types/interfaces/TripleElement';
import uniqid from 'uniqid'
import TripleInput from 'components/TripleInput/TripleInput';
import { toTitleCase } from 'utils/stringUtils';
import { setProp, setNewTriple } from './insertTripleSlice';
import Prop from 'agora-types/interfaces/IProp';

const InsertTriple: React.FC = () => {

    const dispatch = useDispatch()
    const { newTriple, addError, cls, prop, subject } = useSelector((state: RootState) => state.insertTripleReducer)

    const typeRange: {
        kind: 'entity' | 'literal', 
        value: URI
    }[] = prop ? prop.dataTypeRange.map((uri: URI): {
        kind: 'entity' | 'literal', 
        value: URI
    } => {
        return {
            value: uri,
            kind: 'literal'
        }
    }).concat(prop.entityRange.map((uri: URI): { 
        kind: 'entity' | 'literal', 
        value: URI
    } => {
        return {
            kind: 'entity',
            value: uri
        }
    })) : []

    return (
        <div>
        {prop ? <div> <UriSelect
        uri={prop.URI}
        uris={cls.requiredProperties.concat(cls.suggestedProperties).reduce((map: any, pr: Prop) => {
            map[pr.URI] = {
                label: toTitleCase(pr.label),
                value: pr
            };
            return map;
        }, {})}
        onSelect={(pr: any) => {
            let sp: SingleProp = pr as SingleProp
            dispatch(setNewTriple(null))
            dispatch(setProp(sp))
        }}
    /> <Divider /> 
    <TripleInput
        kind="insert"
        prop={prop}
        value={newTriple ? newTriple.object : null}
        typeRange={typeRange}
        error={addError}
        removable={false}
        onChange={(pred: URI, object: ITripleElement) => {
            dispatch(setNewTriple({
                subject: subject,
                predicate: pred,
                object: object
            })
        )}}
        onDelete={console.log}
    /> </div>: null}
    </div>)
};

export default InsertTriple
import { Menu } from 'antd';
import SearchBox from 'features/Discovery/Search/SearchBox';
import React from 'react';

interface NavBarProps {
    theme: 'dark' | 'light' 
}

export default function NavBar(props: NavBarProps) {

    return (
            <Menu
                theme={props.theme}
                mode="horizontal"
                style={{ lineHeight: '64px' }}
            >
                <Menu.Item disabled key="1">
                    <SearchBox onSelect={console.log}/>
                </Menu.Item>
                <Menu.Item key="2"> Profile </Menu.Item>
                <Menu.Item key="3"> Explore </Menu.Item>
            </Menu>
       )
}
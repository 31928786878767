import { ITripleElement, Literal, URI, ITriple } from 'agora-types';
import React from 'react';
import DateInput from './DateInput/DateInput';
import TextInput from './TextInput/TextInput';
import mappings from './mappings.json'
import dateFormat from 'dateformat'
import GeoInput from './GeoInput/GeoInput';
import TimeInput from './TimeInput/TimeInput';
import SearchBox from '../../features/Discovery/Search/SearchBox';

interface ObjectInputProps {
    error: string
    value: ITripleElement
    disabled?: boolean
    onChange: (object: ITripleElement) => void
}

export default function ObjectInput(props: ObjectInputProps): JSX.Element {

    console.log(props)

    const renderInput = (uri: URI): JSX.Element => {
        let input: JSX.Element

        if (mappings.Geo.includes(uri.value)) {
            input = <GeoInput disabled={props.disabled} value={props.value ? props.value.value : ''} error={props.error} onSelect={(locatation: string) => props.onChange({ value: locatation, dataType: uri, kind: 'literal'})}/>
        } else if (mappings.Date.includes(uri.value)) {
            input = <DateInput disabled={props.disabled} value={props.value ? props.value.value : ''} error={props.error} onSelect={(date: Date) => props.onChange({ value: dateFormat(date, `isoDate`), dataType: uri, kind: 'literal'})}/>
        } else if (mappings.DateTime.includes(uri.value)) {
            input = <DateInput showTime disabled={props.disabled} value={props.value ? props.value.value : ''} error={props.error} onSelect={(date: Date) => props.onChange({ value: dateFormat(date, `yyyy-mm-dd'T'HH:MM:ss`), dataType: uri, kind: 'literal'})}/>
        } else if (mappings.Time.includes(uri.value)) {
            input = <TimeInput disabled={props.disabled} value={props.value ? props.value.value : ''} error={props.error} onChange={(time: Date) => props.onChange({ value: dateFormat(time, `isoTime`), dataType: uri, kind: 'literal'})}/>
        } else if (mappings.Text.includes(uri.value)) {
            input = <TextInput disabled={props.disabled} value={props.value ? props.value.value : ''} error={props.error} onChange={(value: string) => props.onChange({ value: value, dataType: uri, kind: 'literal'})}/>
        } else {
            input = <TextInput disabled={props.disabled} value={props.value ? props.value.value : ''} error={props.error} onChange={(value: string) => props.onChange({ value: value, dataType: uri, kind: 'literal'})}/>
        }

        // if (uri.value === "http://franz.com/ns/allegrograph/5.0/geo/nd#_lat_la_-9.+1_+9.+1_+1.-5_+1.-3_lon_lo_-1.8+2_+1.8+2_+1.-5") {
        //     input = <GeoInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onSelect={(locatation: string) => props.onChange({ value: locatation, dataType: uri, kind: 'literal'})}/>
        // } else if (uri.value === "http://www.w3.org/2001/XMLSchema#date") {
        //     input = <DateInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onSelect={(date: Date) => props.onChange({ value: dateFormat(date, `isoDate`), dataType: uri, kind: 'literal'})}/>
        // } else if (uri.value === "https://agora-data.com/Class/GeoCoordinate") {
        //     input = <GeoInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onSelect={(locatation: string) => props.onChange({ value: locatation, dataType: uri, kind: 'literal'})}/>
        // } else {
        //     switch (uri.label) {
        //             case "Text":
        //                 input = <TextInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onChange={(value: string) => props.onChange({ value: value, dataType: uri, kind: 'literal'})}/>
        //                 break
        //             case "Date":
        //                 input = <DateInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onSelect={(date: Date) => props.onChange({ value: dateFormat(date, `isoDate`), dataType: uri, kind: 'literal'})}/>
        //                 break
        //             case "Time":
                        // input = <TimeInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onChange={(time: Date) => props.onChange({ value: dateFormat(time, `isoTime`), dataType: uri, kind: 'literal'})}/>
        //                 break
        //             case "Date Time":
                        // input = <DateInput showTime disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onSelect={(date: Date) => props.onChange({ value: dateFormat(date, `yyyy-mm-dd'T'HH:MM:ss`), dataType: uri, kind: 'literal'})}/>
        //                 break
        //             case 'Geographic Coordinate':
        //                 input = <GeoInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onSelect={(locatation: string) => props.onChange({ value: locatation, dataType: uri, kind: 'literal'})}/>
        //                 break
        //             case 'location':
        //                 input = <GeoInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onSelect={(locatation: string) => props.onChange({ value: locatation, dataType: uri, kind: 'literal'})}/>
        //                 break
        //             default:
        //                 input = <TextInput disabled={props.disabled} value={props.defaultValue ? props.defaultValue.value : ''} error={props.error} onChange={(value: string) => props.onChange({ value: value, dataType: uri, kind: 'literal'})}/>
        //     }
        // }
        
        return input
    }
    
    const { value } = props

    if (value) {
        if (value.kind === 'literal') {
            return renderInput(value.dataType)
        } else if (value.kind === 'uri') {
            if (value.instanceOf)
                return <SearchBox 
                    instances={[value.instanceOf.value.split('/').reverse()[0]]}
                    onSelect={(search: string) => props.onChange({ kind: 'uri', value: search, instanceOf: value.instanceOf})}/>
            } else {
                throw new Error("Default Value URI must have instanceOf Property")
            }
    }  else {
        return <div> No Value to Render </div>
    }
}

import { Button, Form, Card, Divider, Row, Col } from 'antd';
import { IProp, ITripleElement, URI } from 'agora-types';
import ObjectInput from 'components/ObjectInput/ObjectInput';
import React, { useState, useEffect } from 'react';
import { DeleteOutlined, CheckOutlined, SyncOutlined } from '@ant-design/icons';
import UriSelect from '../UriSelect/UriSelect';

interface TripleInputProps {
    prop: IProp
    error: string
    typeRange: { 
        kind: 'entity' | 'literal', 
        value: URI
    }[]
    removable: boolean
    kind: 'insert' | 'edit'
    onChange: (pred: URI, object: ITripleElement) => void
    onDelete: () => void
    onFinishEdit?: () => void
    value: ITripleElement
}

export default function TripleInput(props: TripleInputProps): JSX.Element {

    const { value, typeRange } = props

    // let uri: { 
    //     kind: 'entity' | 'literal', 
    //     value: URI
    // }
    // let val: ITripleElement = props.defaultValue

    // if (props.defaultValue && props.defaultValue.kind == 'literal' && props.defaultValue.dataType.value) {
    //     uri = {
    //         value: props.defaultValue.dataType,
    //         kind: props.defaultValue.kind
    //     }
    // } else
    //     uri = props.typeRange[0]

    // const [type, setType] : [{ 
    //     kind: 'entity' | 'literal', 
    //     value: URI
    // }, any] = useState(uri)

    // const [value, setValue] : [ITripleElement, any] = useState(val)

    const updateValue = (type: { kind: 'entity' | 'literal', value: URI }) => {
        if (type.kind === 'entity') {
            props.onChange(
                { value: props.prop.URI, label: props.prop.label, kind: 'uri' }, 
                { kind: 'uri', value: '', instanceOf: type.value }
            )
        } else {
            props.onChange(
                { value: props.prop.URI, label: props.prop.label, kind: 'uri' }, 
                { kind: 'literal', value: '', dataType: { 
                        kind: 'uri',
                        value: type.value.value 
                    }
                }
            )
        }
    }


    useEffect(() => {
        
        if (!value) {
            updateValue(typeRange[0])
        }

    }, [value])

    // console.log('triple input types', props.typeRange, type)

    let uris = typeRange.reduce((map: any, type: { 
        kind: 'entity' | 'literal', 
        value: URI
    }) => {
        map[type.value.value] = {
            label: type.value.label,
            value: type
        };
        return map;
    }, {});

    
    if (value) {

        let uri: string = ''
        if (value.kind === 'uri') {
            if (value.instanceOf) {
                uri = value.instanceOf.value
            } else {
                throw new Error("Default Value URI must have instanceOf property")
            }
        } else {
            uri = value.kind === 'literal' ? value.dataType.value : value.value
        }

        return (
            <Row align="top" gutter={8}>
                <Col span={6}>
                    <UriSelect uris={uris} uri={uri} onSelect={(uri: any) => {
                        let type = uri as { kind: 'entity' | 'literal', value: URI }
                        updateValue(type)
                    }}/>
                </Col>

                <Col 
                    flex={props.removable ? 6 : 4}
                    span={16}>
                
                    <ObjectInput 
                        value={value} error={props.error} 
                        onChange={(object: ITripleElement) => props.onChange({ value: props.prop.URI, label: props.prop.label, kind: 'uri' }, object)}/> 

                </Col>

                <Col span={2}>
                    <div style={{textAlign: 'center'}}>
                        {/* {props.kind === 'edit' ? <Button disabled={!!props.error} onClick={() => props.onFinishEdit()} icon={<CheckOutlined /> }/>  : null}
                        {props.kind === 'edit' && props.removable ? <Divider type='vertical'/> : null } */}
                        {props.removable ? <Button onClick={() => props.onDelete()} icon={<DeleteOutlined /> }/> : null}
                    </div>
                </Col>
            </Row>
    )} else {
        return (<div> No Value Yet </div>)
    }
}
import { Input } from 'antd';
import React from 'react';

interface TextInputProps {
    onChange: (content: string) => void
    error: string
    value: string
    disabled?: boolean
}

const TextInput = (props: TextInputProps): JSX.Element => {
    return <div> <Input disabled={props.disabled} defaultValue={props.value} style={props.error ? {borderColor: 'red'} : {}} onChange={(e: any) => {
        props.onChange(e.currentTarget.value)
    }} placeholder={"Enter Text..."} />
    {props.error ? <span style={{color: 'red'}} > {props.error} </span> : null}
    </div>

}

export default TextInput
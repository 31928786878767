import { combineReducers } from '@reduxjs/toolkit'
import insertEntityReducer from '../features/Manage/InsertEntity/insertEntitySlice'
import insertTripleReducer from '../features/Manage/InsertTriple/insertTripleSlice'
import updateReducer from '../features/Manage/Update/updateSlice'
import discoveryReducer from '../features/Discovery/discoverySlice'
import searchReducer from '../features/Discovery/Search/searchSlice'



const rootReducer = combineReducers({
  insertEntityReducer: insertEntityReducer,
  insertTripleReducer: insertTripleReducer,
  discoveryReducer: discoveryReducer,
  updateReducer: updateReducer,
  searchReducer: searchReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
import { IClass, ITripleElement, MultiProp, IProp, SingleProp, ITriple, URI } from 'agora-types';
import PropertyEditor from 'components/PropertyEditor/PropertyEditor';
import React from 'react';
import { List, Card, Collapse, Divider, Tag } from 'antd';
import { toTitleCase } from 'utils/stringUtils';
import MultiPropEditor from 'components/MultiPropEditor/MultiPropEditor';

interface EntityInputProps {
    triples: {
        [pid: string] : {
            [tid: string] : ITriple
        }
    }
    errors: {
        [pid: string]: string
    }
    cls: IClass
    onChange: (tid: string, predicate: URI, object: ITripleElement) => void
    onAdd: (prop: SingleProp) => void
    onDelete: (pid: string, tid: string) => void
    kind: 'insert' | 'edit'
    onFinishEdit?: (pid: string, tid: string) => void
}

export default function EntityInput(props: EntityInputProps): JSX.Element {

    const { triples, onChange, onAdd, onDelete, cls, errors, onFinishEdit } = props
    
    let allProps: IProp[] = (cls.requiredProperties || []).concat(cls.suggestedProperties)

    allProps = allProps.filter((prop: IProp) => prop.label !== 'date entered')
    
    return (
        <Collapse>
            {allProps.map((iprop: IProp, index: number) => {
                switch (iprop.kind) {
                    case 'singleprop':
                        return <Collapse.Panel style={errors[iprop.URI] ? {borderColor: 'red'} : {}} key={index} header={
                        <div>
                            <span style={errors[iprop.URI] ? {color: 'red'} : {}}> {toTitleCase(iprop.label)} </span>
                            <Divider type="vertical"/>
                            <Tag color={iprop.required ? 'red' : 'cyan'}>
                                {iprop.required ? 'Required' : 'Suggested'}
                            </Tag> 
                            <Tag color={iprop.unique ? 'red' : 'cyan'}>
                                {iprop.unique ? 'Unique' : 'Non-Unique'}
                            </Tag> 
                        </div>}> <PropertyEditor
                            kind={props.kind}
                            onFinishEdit={(tid: string) => onFinishEdit(iprop.URI, tid)}
                            error={errors[iprop.URI]}
                            prop={iprop}
                            triples={triples[iprop.URI]}
                            onAdd={onAdd}
                            onChange={onChange}
                            onDelete={(tid: string) => onDelete(iprop.URI, tid)}
                        /> </Collapse.Panel>
                    case 'multiprop':
                        const mpTrips = Object.keys(triples).reduce((map: any, pid: string) => {
                            if (iprop.options[pid]) {
                                map[pid] = triples[pid]
                            }
                            return map;
                        }, {});

                        return <Collapse.Panel key={index} header={
                            <div>
                                {toTitleCase(iprop.label)}
                                <Divider type="vertical"/>
                                <Tag color={iprop.required ? 'red' : 'cyan'}>
                                    {iprop.required ? 'Required' : 'Suggested'}
                                </Tag> 
                                <Tag color={iprop.unique ? 'red' : 'cyan'}>
                                    {iprop.unique ? 'Unique' : 'Non-Unique'}
                                </Tag> 
                            </div>
                        }>
                           <MultiPropEditor 
                                onFinishEdit={(tid: string) => onFinishEdit(iprop.URI, tid)}
                                error={errors[iprop.URI]}
                                prop={iprop}
                                triples={mpTrips}
                                onAdd={onAdd}
                                onChange={onChange}
                                onDelete={(pid: string, tid: string) => onDelete(pid, tid)}
                           />
                        </Collapse.Panel>
                    default:
                        return <div key={index}> Neither MProp or Prop</div>
                }
            })}
        </Collapse>
    )
}
import { TimePicker } from 'antd';
import React from 'react';
import moment from 'moment';

interface TimeInputProps {
    onChange: (time: Date) => void
    error: string
    value: string
    disabled?: boolean
}

const TimeInput = (props: TimeInputProps): JSX.Element => {
    return <div>
        <TimePicker 
            use12Hours 
            disabled={props.disabled} 
            defaultValue={props.value ? moment(props.value) : moment()} 
            style={props.error ? {borderColor: 'red'} : {}} 
            onChange={(time: any, timeString: string) => props.onChange(new Date(time))} /> 
        {props.error ? <span style={{color: 'red'}} > {props.error} </span> : null}
    </div>
}

export default TimeInput
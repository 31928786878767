import { Breadcrumb, Layout, Menu, Divider } from 'antd';
import React from 'react';
import { Provider, useDispatch } from 'react-redux';
import './App.css';
import store from './store';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import NavBar from 'components/Navigation/NavBar';
import InsertDialog from 'features/Manage/InsertDialog';
import Feed from 'features/Discovery/Discovery';
import SidebarFilter from 'features/Discovery/Feed/SidebarFilter';
import Discovery from 'features/Discovery/Discovery';


const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default function App(): JSX.Element {
  
  return (<Provider store={store}>
      <Layout>
    <Header className="header">
      <div className="logo" style={{
        'width': '120px',
        'height': '31px',
        'background': 'rgba(255, 255, 255, 0.2)',
        'margin': '16px 28px 16px 0',
        'float': 'left'
      }}/>
      <NavBar theme="dark"/>
    </Header>
    <Layout>
      <SidebarFilter />
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: '#fff'
          }}
        >
          <Discovery />
          <Divider />
        </Content>
      </Layout>
    </Layout>
  </Layout>
  </Provider>)
}
import { DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import { googleMapURL } from '../../../config.json'
import { GoogleMap, withGoogleMap, withScriptjs, Marker } from 'react-google-maps'

interface GeoInputProps {
    value: string
}

const GMap = (props: GeoInputProps): JSX.Element => {

    let map: React.RefObject<GoogleMap>
    let searchBox: any;

    useEffect(() => {
        if (props.value && props.value !== '') {
            let tokens = props.value.match(/([\+-][\d\.]+)([\+-][\d\.]+)/)
            if (tokens) {
                let lat: number = parseFloat(tokens[1])
                let lng: number = parseFloat(tokens[2])
                setCenter({ lat: lat, lng: lng})
            }
        }

    }, [props.value])

    const [center, setCenter]: [{ lat: number, lng: number }, any] = useState({ lat: -34.397, lng: 150.644 })


    return  <GoogleMap 
        ref={map} 
        onClick={console.log} 
        defaultZoom={8}
        center={center}
        defaultCenter={center}>               
                <Marker position={center} />
        </GoogleMap>
}

const GeoInputWithJS = withScriptjs(withGoogleMap(GMap))

const Map = (props: GeoInputProps): JSX.Element => {
    
    return  <GeoInputWithJS 
        googleMapURL={googleMapURL}
        loadingElement={<div> Loading </div>}
        containerElement={<div style={{ height: `400px` }} />}
        {...props}
        mapElement={<div style={{ height: `100%` }} />}> 
        
        </GeoInputWithJS>
}

export default Map

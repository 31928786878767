import { IClass, Shape, ITriple, IEntity, tripleToString, tripleElementToString, URI, ITripleElement, Literal } from "agora-types";
import { get, HttpResponse } from './request';


export class GraphDBApi {

	endpoint: string

	constructor(endpoint: string = 'https://api.agora-data.com/api/v1') {
		
		let host: string = window.location.hostname

		if (host === 'localhost') {
			endpoint = 'https://api.stage.agora-data.com/api/v1'
		} else if (host === 'stage.agora-data.com') {
			endpoint = 'https://api.stage.agora-data.com/api/v1'
		} else if (host === 'agora-data.com') {
			endpoint = 'https://api.agora-data.com/api/v1'
		}

		console.log('host: ', host, '\nusing endpoint: ', endpoint)

		this.endpoint = endpoint
	}

	async search ( queryTerm: string, cls?: string, levDist: number = 1): Promise<any> {

		let fullUrl = this.endpoint + `/search/${queryTerm}`

	
		try {
			// example consuming code
			const response = await get<any[]>(fullUrl);
			const searchResults: any[] = response.parsedBody
			return searchResults
		} catch(e) {
			console.log(e)
		}
		
		return []
	}

	async getInstances( className: string ): Promise<IClass[]> {

		// Make query for class shape
		let fullUrl = encodeURI(this.endpoint + `/instances/${className}`)
		
		try {
			const response: HttpResponse<IClass[]> = await get<IClass[]>(fullUrl);
			const props: IClass[] = response.parsedBody
			return props
		} catch(e) {
			console.log(e)
		}
		
		return []
	}

	async getClassShape( className: string ): Promise<Shape> {

		// Make query for class shape
		let fullUrl = encodeURI(this.endpoint + `/class/${className.replace(" ", "")}/shape`)
		
		try {
			const response: HttpResponse<Shape> = await get<Shape>(fullUrl);
			const shape: Shape = response.parsedBody
			return shape
		} catch(e) {
			console.log(e)
		}
		
		return null
	}

	async getClasses(classType: string) : Promise<IClass[]> {

		// query for all classes
		const fullUrl = this.endpoint + `/class/${classType}/all`

		try {
			const response: HttpResponse<IClass[]> = await get<IClass[]>(fullUrl);
			const classes: IClass[] = response.parsedBody
			return classes
		} catch(e) {
			console.log(e)
		}
		
		return []
	}

	async insert(triples: ITriple[], context?: string ): Promise<number> {

		const fullUrl = this.endpoint + `/insert`

		let body = ""
		for (let triple of triples) {
		    body = body + triple.toString() + '\n'
		}
		let datum = { data: body.substring(0, body.length - 1) }

		try {
			// example consuming code
			const config = {
				method: 'post',
				headers: {
				  'Content-Type': 'application/json',
				},
				body: JSON.stringify(datum)
			}

			const response: any = await fetch(fullUrl, config)
			return response
		} catch(e) {
			console.log(e)
		}

		return 0
	}

	// async getEntity( id: string ): Promise<Entity> {

	// 	// query for entity properties
	// 	let fullUrl = this.endpoint + `/entity/${id}`

	// 	try {
	// 		let response: HttpResponse<ITriple[]> = await get<ITriple[]>(fullUrl);
	// 		let statements : ITriple[] = response.parsedBody
	// 		let entity = new Entity(statements)
	// 		return entity
	// 	} catch(e) {
	// 		console.log(e)
	// 	}
		
	// 	return null
	// }
}

export async function search( queryTerm: string, instances?: string[], levDist: number = 1): Promise<any[]> {

	let fullUrl = `https://api.stage.agora-data.com/api/v1/search/${queryTerm}`

	// query for all classes
	let url = new URL(fullUrl);
	if (instances) {
		instances.forEach((cls: string) => {
			url.searchParams.append('instance', `agc:${cls}`)
		})
	}

	console.log(url.toString())

	try {
		// example consuming code
		const response = await get<any[]>(url.toString());
		const searchResults: any[] = response.parsedBody
		return searchResults
	} catch(e) {
		console.log(e)
	}
	
	return []
}

export async function getClassShape( className: string ): Promise<Shape> {

	// Make query for class shape
	// let fullUrl = encodeURI(`http://localhost:8081/api/v1/class/${className.replace(" ", "")}/shape`)
	let fullUrl = encodeURI(`https://api.stage.agora-data.com/api/v1/class/${className.replace(" ", "")}/shape`)
	
	try {
		const response: HttpResponse<Shape> = await get<Shape>(fullUrl);
		const shape: Shape = response.parsedBody
		return shape
	} catch(e) {
		console.log(e)
	}
	
	return null
}

export async function getClasses(classType: string) : Promise<IClass[]> {

	// query for all classes
	const fullUrl = `https://api.stage.agora-data.com/api/v1/class/${classType}/all`

	try {
		const response: HttpResponse<IClass[]> = await get<IClass[]>(fullUrl);
		const classes: IClass[] = response.parsedBody
		return classes
	} catch(e) {
		console.log(e)
	}
	
	return []
}

export async function getEntities(instanceType?: String) : Promise<IEntity[]> {

	// query for all classes
	let fullUrl: string
	if (instanceType)
		fullUrl = `https://api.stage.agora-data.com/api/v1/Entities?instance=agc:${instanceType}`
	else
		fullUrl = `https://api.stage.agora-data.com/api/v1/Entities`

	try {
		const response: HttpResponse<IEntity[]> = await get<IEntity[]>(fullUrl);
		const entities: IEntity[] = response.parsedBody
		return entities
	} catch(e) {
		console.log(e)
	}
	
	return []
}

export async function deleteEntity(subjectURI: URI) : Promise<any> {

	// query for all classes
	const fullUrl = `https://api.stage.agora-data.com/api/v1/delete?subj=${tripleElementToString(subjectURI)}`

	const config = {
		method: 'delete',
	}

	try {
		const response: any = await fetch(fullUrl, config);
		// const num: number = response.parsedBody
		return response
	} catch(e) {
		console.log(e)
	}
	
	return 0
}

export async function deleteTriple(triple: ITriple) : Promise<any> {

	let subj: string = tripleElementToString(triple.subject)
	let pred: string = tripleElementToString(triple.predicate)
	let obj: string = tripleElementToString(triple.object)

	// query for all classes
	let url = new URL(`https://api.stage.agora-data.com/api/v1/delete`);
	url.searchParams.append('subj', subj);
	url.searchParams.append('pred', pred);
	url.searchParams.append('obj', obj);

	const config = {
		method: 'delete',
	}

	try {
		const response: any = await fetch(url.toString(), config);
		// const num: number = response.parsedBody
		return response
	} catch(e) {
		console.log(e)
	}
	
	return 0
}

export async function updateTriple(oldTriple: ITriple, newObj: ITripleElement) : Promise<number> {

	console.log(newObj)
	let subj: string = tripleElementToString(oldTriple.subject)
	let pred: string = tripleElementToString(oldTriple.predicate)
	let obj: string = tripleElementToString(oldTriple.object)
	let nObj: string = tripleElementToString(newObj)

	let url = new URL(`https://api.stage.agora-data.com/api/v1/update`);
	url.searchParams.append('subj', subj);
	url.searchParams.append('pred', pred);
	url.searchParams.append('obj', obj);
	url.searchParams.append('newObj', nObj);

	const config = {
		method: 'put',
	}

	try {
		const response: any = await fetch(url.toString(), config);
		return response
	} catch(e) {
		console.log(e)
	}

	return 0
}

export async function insert(triples: ITriple[], context?: string ) : Promise<number> {

	const fullUrl = `https://api.stage.agora-data.com/api/v1/insert`
	// const fullUrl = `http://localhost:8081/api/v1/insert`

	try {
		// example consuming code
		const config = {
			method: 'post',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({ data: triples })
		}

		console.log(config)

		const response: any = await fetch(fullUrl, config)
		return response
	} catch(e) {
		console.log(e)
	}

	return 0
}

// export interface Label {
//   id: number
//   name: string
//   color: string
// }

// export interface User {
//   login: string
//   avatar_url: string
// }

// export interface Issue {
//   id: number
//   title: string
//   number: number
//   user: User
//   body: string
//   labels: Label[]
//   comments_url: string
//   state: 'open' | 'closed'
//   comments: number
// }

// export interface RepoDetails {
//   id: number
//   name: string
//   full_name: string
//   open_issues_count: number
// }

// export interface Comment {
//   id: number
//   body: string
//   user: User
//   created_at: string
//   updated_at: string
// }

// export interface IssuesResult {
//   pageLinks: Links | null
//   pageCount: number
//   issues: Issue[]
// }

// const isLastPage = (pageLinks: Links) => {
//   return (
//     Object.keys(pageLinks).length === 2 && pageLinks.first && pageLinks.prev
//   )
// }

// const getPageCount = (pageLinks: Links) => {
//   if (!pageLinks) {
//     return 0
//   }
//   if (isLastPage(pageLinks)) {
//     return parseInt(pageLinks.prev.page, 10) + 1
//   } else if (pageLinks.last) {
//     return parseInt(pageLinks.last.page, 10)
//   } else {
//     return 0
//   }
// }

// export async function getIssues(
//   org: string,
//   repo: string,
//   page = 1
// ): Promise<IssuesResult> {
//   const url = `https://api.github.com/repos/${org}/${repo}/issues?per_page=25&page=${page}`

//   try {
//     const issuesResponse = await axios.get<Issue[]>(url)
//     let pageCount = 0
//     const pageLinks = parseLink(issuesResponse.headers.link)

//     if (pageLinks !== null) {
//       pageCount = getPageCount(pageLinks)
//     }

//     return {
//       pageLinks,
//       pageCount,
//       issues: issuesResponse.data
//     }
//   } catch (err) {
//     throw err
//   }
// }

// export async function getRepoDetails(org: string, repo: string) {
//   const url = `https://api.github.com/repos/${org}/${repo}`

//   const { data } = await axios.get<RepoDetails>(url)
//   return data
// }

// export async function getIssue(org: string, repo: string, number: number) {
//   const url = `https://api.github.com/repos/${org}/${repo}/issues/${number}`

//   const { data } = await axios.get<Issue>(url)
//   return data
// }

// export async function getComments(url: string) {
//   const { data } = await axios.get<Comment[]>(url)
//   return data
// }
import { ITriple, IEntity } from 'agora-types';
import PropertyEditor from 'components/PropertyEditor/PropertyEditor';
import React from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Card, Collapse, Divider, Tag, Descriptions, Button } from 'antd';
import { toTitleCase } from 'utils/stringUtils';
import Map from '../../../ObjectRender/Map/Map'


interface EntityFeedProps {
    entity: IEntity
    onDelete: () => void
    onAdd: () => void
    onDeleteTriple: (trip: ITriple) => void
    onEditTriple: (trip: ITriple) => void
}

export default function EntityFeed(props: EntityFeedProps): JSX.Element {

    let label: React.ReactNode = null
    let description: React.ReactNode = ''
    let dateEntered: string = ''
    let instanceOf: string = ''
    let map: React.ReactNode = null
    let leftovers: ITriple[] = []

    props.entity.statements.forEach((triple: ITriple) => {
        switch (triple.predicate.label) {
            case 'label': 
                label = <div> 
                    {toTitleCase(triple.object.value)} 
                    <Divider type='vertical' /> 
                    <Button onClick={() => props.onEditTriple(triple)} icon={<EditOutlined /> }/>
                    <Button onClick={() => props.onDeleteTriple(triple)} icon={<DeleteOutlined /> }/>
                </div> 
                break
            case 'description':
                description = <div> 
                    {triple.object.value} 
                    <Divider type='vertical' /> 
                    <Button onClick={() => props.onEditTriple(triple)} icon={<EditOutlined /> }/>
                    <Button onClick={() => props.onDeleteTriple(triple)} icon={<DeleteOutlined /> }/>
                </div>
                break
            case 'date entered':
                dateEntered = triple.object.value
                break
            case 'instance of':
                instanceOf = triple.object.value
                break
            case 'location':
                if (triple.object.kind === "literal") {
                    map = <div> 
                        <Map value={triple.object.value}/>
                        <Button onClick={() => props.onEditTriple(triple)} icon={<EditOutlined /> }/>
                        <Button onClick={() => props.onDeleteTriple(triple)} icon={<DeleteOutlined /> }/>
                    </div>
                }
                else
                    leftovers.push(triple)
                break
            default:
                leftovers.push(triple)
        }
    }) 

    console.log(leftovers)
    return (
        <Card
        actions={[
            <PlusOutlined onClick={() => props.onAdd()} key="edit" />,
            <DeleteOutlined onClick={() => props.onDelete()} key="ellipsis" />,
          ]}
        >
            <Card.Meta
            title={label}
            description={<div> 
                {description} 
                <Divider />
                <Tag color="blue"> {instanceOf.split('/').reverse()[0]} </Tag> 
                <Tag color="green"> {new Date(dateEntered).toLocaleDateString()} </Tag>
                {map ? <Divider /> : null}
                {map}
            </div>}
          />
          <Divider />
          <List 
            size="large"
            dataSource={leftovers}
            renderItem={(trip: ITriple) => {
                let obj: React.ReactNode;
                switch (trip.object.kind) {
                    case 'uri':
                        obj = <a href='/'> {trip.object.value} </a>
                        break
                    case 'literal':
                        obj = <div> {trip.object.value} </div>
                        break
                    default:
                        obj = <div> {trip.object.value}</div>
                }

                obj = <List.Item
                    actions={[
                        trip.object.kind !== 'uri' ? <Button onClick={() => props.onEditTriple(trip)} icon={<EditOutlined /> }/> : null,
                        <Button onClick={() => props.onDeleteTriple(trip)} icon={<DeleteOutlined /> }/>]}
                >
                <List.Item.Meta
                    title={<a href={trip.predicate.value}>{toTitleCase(trip.predicate.label)}</a>}
                    description={obj} />
                </List.Item>

                return obj
            }}
            />
        </Card>
    )
}
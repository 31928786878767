import { DatePicker } from 'antd';
import React from 'react';
import moment from 'moment';

interface DateInputProps {
    onSelect: (date: Date) => void
    error: string
    value: string
    disabled?: boolean
    showTime?: boolean
}

const { RangePicker } = DatePicker;


const DateInput = (props: DateInputProps): JSX.Element => {
    return <div>
        <DatePicker
            showTime={props.showTime}
            disabled={props.disabled} 
            defaultValue={props.value ? moment(props.value) : moment()} 
            style={props.error ? {borderColor: 'red'} : {}} 
            onSelect={(val: any) => props.onSelect(new Date(val))} > 
        Pick a Date

        </DatePicker>
        {props.error ? <span style={{color: 'red'}} > {props.error} </span> : null}
    </div>
}

export default DateInput
import { Button, Modal } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { setUpdateModal, submitUpdatedTriple } from './updateSlice';
import UpdateTriple from './updateTriple';


const UpdateTripleModal: React.FC = () => {

    const dispatch = useDispatch()
    const { updateModal, updateLoading, oldTriple, updatedTriple } = useSelector((state: RootState) => state.updateReducer)
    
    return <div>
        <Modal
            title="Edit Item"
            width="70%"
            footer={[
                <Button key="back" onClick={() => dispatch(setUpdateModal(false))}>
                    Cancel
                </Button>,
                <Button key="submit" loading={updateLoading} type="primary" onClick={() => dispatch(submitUpdatedTriple(oldTriple, updatedTriple))}>
                    Submit
                </Button>
                ]}
            visible={updateModal}
            onOk={() => dispatch(setUpdateModal(false))}
            onCancel={() => dispatch(setUpdateModal(false))}>
                    
            <UpdateTriple />

        </Modal>
    </div>
};

export default UpdateTripleModal

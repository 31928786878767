import { Breadcrumb, Layout, Menu, Checkbox } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import store from './store';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import { RootState } from 'app/rootReducer';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { onClassFilterChange } from '../discoverySlice';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default function SidebarFilter(): JSX.Element {
  
  const dispatch = useDispatch()

  const { classFilter } = useSelector((state: RootState) => state.discoveryReducer)
  const classFilterSet = new Set(classFilter)

  const onFilterChange = (checked: boolean, cls: string) => {

      if (checked) {
        classFilterSet.add(cls)
      } else {
          classFilterSet.delete(cls)
      }

      console.log(checked, cls, classFilterSet)
      dispatch(onClassFilterChange(Array.from(classFilterSet)))
  }

    return (<Sider width={200} className="site-layout-background">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub2']}
          style={{ height: '100%', borderRight: 0 }}
        >
          <SubMenu
            key="sub1"
            title={
              <span>
                <UserOutlined />
                Datum Filter
              </span>
            }
          >
            <Menu.Item key="1" disabled> Geographic </Menu.Item>
            <Menu.Item key="2" disabled> Temporal </Menu.Item>
            <Menu.Item key="3" disabled> Relational </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub2"
            title={
              <span>
                <LaptopOutlined />
                Class Filter
              </span>
            }
          >
            
            <Menu.Item key="5">
                <Checkbox 
                    onChange={(e: CheckboxChangeEvent) => onFilterChange(e.target.checked, "Person")} 
                    checked={classFilterSet.has("Person")}> 
                    People 
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="6">
                <Checkbox 
                    onChange={(e: CheckboxChangeEvent) => onFilterChange(e.target.checked, "Place")}                     
                    checked={classFilterSet.has("Place")}> 
                    Places 
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="7">
                <Checkbox 
                    onChange={(e: CheckboxChangeEvent) => onFilterChange(e.target.checked, "Event")} 
                    checked={classFilterSet.has("Event")}> 
                    Events 
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="8">
                <Checkbox 
                    onChange={(e: CheckboxChangeEvent) => onFilterChange(e.target.checked, "Thing")} 
                    checked={classFilterSet.has("Thing")}> 
                    Things 
                </Checkbox>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub3"
            title={
              <span>
                <NotificationOutlined />
                subnav 3
              </span>
            }
          >
            <Menu.Item key="9">option9</Menu.Item>
            <Menu.Item key="10">option10</Menu.Item>
            <Menu.Item key="11">option11</Menu.Item>
            <Menu.Item key="12">option12</Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    )
}
import { DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import { googleMapURL } from '../../../config.json'
import { GoogleMap, withGoogleMap, withScriptjs, Marker } from 'react-google-maps'
import moment from 'moment';

const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

interface GeoInputProps {
    onSelect: (location: string) => void
    error: string
    value: string
    disabled?: boolean
}

const GMap = (props: GeoInputProps): JSX.Element => {

    let map: React.RefObject<GoogleMap>
    let searchBox: any;

    useEffect(() => {
        if (props.value && props.value !== '') {
            let tokens = props.value.match(/([\+-][\d\.]+)([\+-][\d\.]+)/)
            if (tokens) {
                let lat: number = parseFloat(tokens[1])
                let lng: number = parseFloat(tokens[2])
                setCenter({ lat: lat, lng: lng})
            }
        }

    }, [props.value])

    const [center, setCenter]: [{ lat: number, lng: number }, any] = useState({ lat: -34.397, lng: 150.644 })
    
    const onPlacesChanged = () => {
            const places = searchBox.getPlaces();
            console.log(places)
            // const bounds = new google.maps.LatLngBounds();
  
            places.forEach((place: any) => {
                console.log('viewport', place.geometry.viewport)
                console.log('location', place.geometry.location)
            });

            const nextMarkers = places.map((place: any) => ({
              position: place.geometry.location,
            }));

            if (nextMarkers[0].position) {
                const center = {
                    lat: nextMarkers[0].position.lat(),
                    lng: nextMarkers[0].position.lng()
                }
                setCenter(center)
                props.onSelect(`&lat=${center.lat}&lon=${center.lng}`)
            }
    }

    console.log(center)

    return  <GoogleMap 
        ref={map} 
        onClick={console.log} 
        defaultZoom={8}
        center={center}
        defaultCenter={center}>
            <SearchBox
                    ref={(ref: any) => searchBox = ref}
                    onPlacesChanged={onPlacesChanged}
                    controlPosition={2}
                >
                    <div>
                    <input
                    type="text"
                    placeholder={''}
                    disabled={props.disabled}
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        marginTop: `27px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        borderColor: props.error ? 'red' : ''
                    }}
                    />
                    {props.error ?  <p style={{
                        color: 'red'
                    }}> {props.error} </p> : null }
                    </div>
                </SearchBox>
               
                <Marker position={center} />
        </GoogleMap>
}

const GeoInputWithJS = withScriptjs(withGoogleMap(GMap))

const GeoInput = (props: GeoInputProps): JSX.Element => {

    let map: React.RefObject<GoogleMap>
    
    return  <GeoInputWithJS 
        googleMapURL={googleMapURL}
        loadingElement={<div> Loading </div>}
        containerElement={<div style={{ height: `400px` }} />}
        {...props}
        mapElement={<div style={{ height: `100%` }} />}> 
        
        </GeoInputWithJS>
}

export default GeoInput

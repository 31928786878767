import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import {
    sendToApi,
    setModal
} from './insertEntitySlice'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import InsertPage from './InsertEntity';
import PreviewEntity from 'components/EntityPreview/EntityPreview';


const InsertDialog: React.FC = () => {

    const dispatch = useDispatch()
    const [open, setOpen] : [boolean, any] = useState(false);
    const [preview, setPreview] : [boolean, any] = useState(false)
    const { content, classes, cls, loadingInsert, openModal } = useSelector((state: RootState) => state.insertEntityReducer)
    const { updateModal } = useSelector((state: RootState) => state.updateReducer)

    const handleOk = async () => {
         dispatch(sendToApi(content))
    }
    
    return <div>
    <Button type="primary" onClick={() => dispatch(setModal({modal: true, insert: true}))}>
        Add Item +
    </Button>
        <Modal
          title="Add Item"
          width="80%"
          visible={openModal}
          footer={[
            <Button key="back" onClick={() => dispatch(setModal({modal: false, insert: true}))}>
              Cancel
            </Button>,
            <Button key="preview" onClick={() => setPreview(true)}>
                Preview
            </Button>,
            <Button key="submit" loading={loadingInsert} type="primary" onClick={() => handleOk()}>
              Submit
            </Button>
          ]}
          onOk={() => dispatch(setModal({modal: false, insert: true}))}
          onCancel={() => dispatch(setModal({modal: false, insert: true}))}
        >
            <InsertPage kind='insert'/>

            <Modal
                title="Preview Item"
                width="70%"
                visible={preview}
                onOk={() => setPreview(false)}
                onCancel={() => setPreview(false)}
            >
                <PreviewEntity entity={content}/>
            </Modal>
        </Modal>
    </div>
};

export default InsertDialog
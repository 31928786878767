import { AutoComplete, Input } from 'antd';
import { fetchSearchResults, setFilter } from './searchSlice';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';

interface SearchProps {
  instances?: string[]
  onSelect: (search: any) => void
}

const Search: React.FC<SearchProps> = (props) => {

  const dispatch = useDispatch()
  const { searchResults } = useSelector((state: RootState) => state.searchReducer)

  useEffect(() => {
    if (props.instances) {
      console.log("found instance in props")
      dispatch(setFilter(props.instances))
    } else {
      console.log("did not find found instance in props")
    }
  }, [])

  const onSearch = async (searchText: string) => {
    dispatch(fetchSearchResults(searchText))

    // let options = [
    //     {
    //         label: renderTitle('Class'),
    //         options: searchResults.filter((value: any) => value.price.includes('Class')).map((elem: any) => renderItem(elem.title, elem.price, elem.description))
    //     },
    //     {
    //         label: renderTitle('Property'),
    //         options: searchResults.filter((value: any) => value.price.includes('Property')).map((elem: any) => renderItem(elem.title, elem.price, elem.description))
    //     },
    //     {
    //         label: renderTitle('Entity'),
    //         options: searchResults.filter((value: any) => value.price.includes('Entity')).map((elem: any) => renderItem(elem.title, elem.price, elem.description))
    //     }
    // ]

  };
  
  const onSelect = (data: string) => {
    props.onSelect(data)
  };

  return (
    <div>
      <AutoComplete
        options={searchResults.map((elem: any, i: number) => renderItem(elem.title, elem.price, elem.description, i))}
        dropdownMatchSelectWidth={500}
        style={{ width: '100%' }}
        onSelect={onSelect}
        onSearch={onSearch}
      >
          <Input.Search size="large" placeholder="Search" />
        </AutoComplete>
      <br />
    </div>
  );
};

const renderItem = (title: string, uri: string, description: string, key: number) => {
  return {
    value: uri,
    label: (
      <div key={key}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        <span>
          {uri}
        </span>
      </div>
          <span> {description} </span>
      </div>
    ),
  };
};

const renderTitle = (title: string) => {
  return (
    <span key={title}>
      {title}
    </span>
  );
};

export default Search
import { IClass, ITripleElement, MultiProp, IProp, SingleProp, ITriple, URI } from 'agora-types';
import PropertyEditor from 'components/PropertyEditor/PropertyEditor';
import React from 'react';
import { List, Card, Collapse, Divider, Tag, Descriptions } from 'antd';
import { toTitleCase } from 'utils/stringUtils';

interface PreviewEntityProps {
    entity: {
        [sid: string] : {
        triples: {
                [pid: string]: {
                    [tid: string]: ITriple
                } 
            }
            class: IClass
        }
    }
}

export default function PreviewEntity(props: PreviewEntityProps): JSX.Element {

    const { entity} = props
        
    return (<Collapse>
            {Object.keys(entity).map((sid: string, index: number) => <Collapse.Panel key={sid + index.toString()} header={sid}>
            <Descriptions title="User Info">
                {Object.keys(entity[sid].triples).map((pid: string) => {
                    console.log(entity[sid].triples[pid])
                    return (
                        Object.keys(entity[sid].triples[pid]).map((tid: string) => {
                            return <Descriptions.Item key={pid + tid} label={entity[sid].triples[pid][tid].predicate.label || ''}> 
                                {entity[sid].triples[pid][tid].object.value || ' '} 
                        </Descriptions.Item>}))
                }
            )}
            </Descriptions>
                
            </Collapse.Panel> )}
        </Collapse>)
}
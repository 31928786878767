import { Button, Form, Divider, Card, Tag } from 'antd';
import { ITripleElement, SingleProp, ITriple, URI } from 'agora-types';
import React from 'react';
import TripleInput from '../TripleInput/TripleInput';
import { toTitleCase } from '../../utils/stringUtils'

interface PropertyEditorProps {
    triples: { [tid: string] : ITriple }
    prop: SingleProp
    error: string
    onChange: (tid: string, predicate: URI, object: ITripleElement) => void
    onAdd: (prop: SingleProp) => void
    onDelete: (tid: string) => void
    onFinishEdit?: (tid: string) => void
    kind: 'insert' | 'edit'
}

export default function PropertyEditor(props: PropertyEditorProps): JSX.Element {

    const { triples, prop, onChange, onDelete, onAdd } = props
    const typeRange: { 
        kind: 'entity' | 'literal', 
        value: URI
    }[] = prop.dataTypeRange.map((uri: URI): { 
        kind: 'entity' | 'literal', 
        value: URI
    } => {
        return {
            value: uri,
            kind: 'literal'
        }
    }).concat(prop.entityRange.map((uri: URI): { 
        kind: 'entity' | 'literal', 
        value: URI
    } => {
        return {
            kind: 'entity',
            value: uri
        }
    }))

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    return (
            <Card
                style={{width: '100%'}}
                title={!prop.unique ? <Button onClick={() => onAdd(prop)}> Add + </Button> : null}>
                <div>
                    <div>
                        {triples ? Object.keys(triples).map((tid: string, i: number) => 
                        <div key={i + 100}>
                                <TripleInput
                                    value={triples[tid].object}
                                    onFinishEdit={() => props.onFinishEdit(tid)}
                                    kind={props.kind}
                                    error={props.error}
                                    prop={prop}
                                    typeRange={typeRange}
                                    removable={!prop.required || i !== 0}
                                    onDelete={() => onDelete(tid)}
                                    onChange={(predicate: URI, object: ITripleElement) => onChange(tid, predicate, object)}/>
                                <Divider />
                        </div>
                        ) : null}
                    </div>
                </div>
            </Card>
    )
}
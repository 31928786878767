import { AutoComplete, Input, Spin, Divider, Collapse, Card, Modal, Button } from 'antd';
import { search } from 'api/graphAPI';
import React, { useState, useEffect } from 'react';
import {
    loadEntities,
    removeEntity,
} from './discoverySlice'
import {
    editTriple,
    setUpdateModal,
    submitUpdatedTriple,
    removeTriple,
    changeUpdatedTriple,
} from '../Manage/Update/updateSlice'

import {
    setAddModal,
    submitNewTriple,
    setNewTriple,
    setProp,
    addTriple
} from '../Manage/InsertTriple/insertTripleSlice'

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import uniqid from 'uniqid'
import { IEntity, ITriple, ITripleElement, SingleProp, URI } from 'agora-types';
import TripleInput from 'components/TripleInput/TripleInput';
import ObjectInput from 'components/ObjectInput/ObjectInput';
import UriSelect from 'components/UriSelect/UriSelect';
import Prop from 'agora-types/interfaces/IProp';
import { toTitleCase } from 'utils/stringUtils';
import { updateTriple } from 'features/Manage/InsertEntity/insertEntitySlice';
import Feed from '../../components/Visualizations/Feed/Feed/Feed'
import UpdateTripleModal from 'features/Manage/Update/updateTripleModal';
import InsertTripleModal from 'features/Manage/InsertTriple/InsertTripleModal';
import InsertEntityModal from 'features/Manage/InsertEntity/InsertEntityModal';

const Discovery: React.FC = () => {

    const dispatch = useDispatch()
    const { loading, entities } = useSelector((state: RootState) => state.discoveryReducer)

    useEffect(() => {
        dispatch(loadEntities())
    }, [])

    if (loading) {
        return (<div style={{textAlign: 'center'}}> <Spin size="large"/> </div>)
    } else {
        return (
            <div>
                <InsertEntityModal /> 
                
                <Feed 
                    entities={entities}
                    loading={loading}
                    editTriple={(trip: ITriple) => dispatch(editTriple(trip))}
                    removeEntity={(subject: URI) => dispatch(removeEntity(subject))}
                    removeTriple={(trip: ITriple) => dispatch(removeTriple(trip))} 
                    addTriple={(subject: URI, classLabel: string) => dispatch(addTriple(subject, classLabel))}
                />
                
                <UpdateTripleModal />

                <InsertTripleModal />

            </div>
        );
    }
};

export default Discovery
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IClass, Literal, ITriple, URI, IProp, MultiProp, SingleProp, Shape, IEntity, ITripleElement } from 'agora-types'
import { AppThunk } from 'app/store'
import { updateTriple, deleteTriple } from '../../../api/graphAPI'
import { loadEntities } from 'features/Discovery/discoverySlice' 

interface UpdateState {
    oldTriple: ITriple,
    updatedTriple: ITriple,
    updateLoading: boolean,
    updateModal: boolean
    updateError: string,
}

const initialState: UpdateState = {
    updatedTriple: null,
    oldTriple: null,
    updateLoading: false,
    updateModal: false,
    updateError: ''
}


const insertDetails = createSlice({
  name: 'insertDetails',
  initialState,
  reducers: {
    setUpdateLoading(state, action: PayloadAction<boolean>) {
        state.updateLoading = action.payload
    },
    setUpdateTriple(state, action: PayloadAction<ITriple>) {
       state.updatedTriple = action.payload
       state.oldTriple = action.payload
    },
    changeUpdatedTriple(state, action: PayloadAction<ITripleElement>) {
        state.updatedTriple.object = action.payload
    },
    setUpdateModal(state, action: PayloadAction<boolean>) {
        state.updateModal = action.payload
    },
    setUpdateError(state, action: PayloadAction<string>) {
        state.updateError = action.payload
    },
    reset(state, action: PayloadAction) {
        return initialState
    }
  }
})

export const {
    setUpdateLoading,
    setUpdateError,
    setUpdateModal,
    setUpdateTriple,
    changeUpdatedTriple,
    reset
} = insertDetails.actions

export default insertDetails.reducer

export const editTriple = (triple: ITriple): AppThunk => async dispatch => {
    dispatch(setUpdateModal(true))
    console.log(triple)
    dispatch(setUpdateTriple(triple))
}

export const removeTriple = (triple: ITriple): AppThunk => async dispatch => {
    dispatch(setUpdateLoading(true))

    try {
        let response: number = await deleteTriple(triple)
        if (response) {
            dispatch(loadEntities())
            dispatch(reset())
        }
    } catch (e) {
        console.log("DELETE TRIPLE ERROR: ", e)
    }
}

export const submitUpdatedTriple = (oldTriple: ITriple, updatedTriple: ITriple): AppThunk => async dispatch => {
    
    let error: string = validate(updatedTriple)

    if (error !== '') {
        dispatch(setUpdateError(error))
    } else {
        dispatch(setUpdateLoading(true))
        try {
            let response: number = await updateTriple(oldTriple, updatedTriple.object)
            if (response) {
                dispatch(loadEntities())
                dispatch(reset())
            }
        } catch (e) {
            console.log("SUBMIT NEW TRIPLE ERROR: ", e)
        }
    }
}

const validate = (newTriple: ITriple): string => {
    if (newTriple.object.value === '') {
        return 'New value cannot be empty. Consider Deleting Instead'
    }

    return ''
}
import {  Spin, Divider, Collapse } from 'antd';
import React, { useState, useEffect } from 'react';
import {
    loadItemClasses,
    loadClassShape,
    setClass,
    addTriple,
    updateTriple,
    deleteTriple
} from './insertEntitySlice'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import UriSelect from '../../../components/UriSelect/UriSelect';
import { IClass, URI, SingleProp } from 'agora-types';
import EntityInput from 'components/EntityEditor/EntityEditor';
import TripleElement from 'agora-types/interfaces/TripleElement';
import uniqid from 'uniqid'

let sid: string = `https://agora-data.com/Entity/${uniqid('Q')}`

interface InsertPageProps {
    kind: 'insert' | 'edit'
}

const InsertPage: React.FC<InsertPageProps> = (props) => {

    const dispatch = useDispatch()
    const { content, cls, loading, errors, insert, classes } = useSelector((state: RootState) => state.insertEntityReducer)
   
    useEffect(() => {
        if (classes.length === 0 && props.kind === 'insert') {
            sid = generateId()
            dispatch(loadItemClasses(sid))
        }
    }, [classes])

    const generateId = () => {
        return `https://agora-data.com/Entity/${uniqid('Q')}`
    }

    let uris = classes.reduce((map: any, cls: IClass) => {
        map[cls.uri] = {
            label: cls.label,
            value: cls
        };
        return map;
    }, {});

    if (loading) {
        return (<div style={{textAlign: 'center'}}> <Spin size="large"/> </div>)
    } else {
        return ( <div>
                {cls && insert ? <UriSelect uri={cls.uri} uris={uris} onSelect={(value: any) => {
                    let cls: IClass = value as IClass
                    dispatch(loadClassShape(sid, cls))
                }}/> : null}
                <Divider />
                <Collapse defaultActiveKey={[0]} accordion>
                    { Object.keys(content).map((sid: string, index: number) => content[sid] ? 
                    <Collapse.Panel key={index} header={`${content[sid].class.label} [${sid.split('/').reverse()[0]}]`}>
                        <EntityInput kind={props.kind} errors={errors[sid] || {}} cls={cls} triples={content[sid].triples} 
                        onAdd={(prop: SingleProp) => dispatch(addTriple(
                            {
                                sid: sid,
                                prop: prop
                            }
                        ))} onChange={(tid: string, pred: URI, object: TripleElement) => dispatch(updateTriple({
                                sid: sid,
                                tid: tid,
                                triple: {
                                    subject: {
                                        value: sid,
                                        kind: 'uri'
                                    },
                                    predicate: pred,
                                    object: object
                                }
                            }))} onDelete={((pid: string, tid: string) => dispatch(deleteTriple({sid: sid, pid: pid, tid: tid })))} /> 
                    </Collapse.Panel> : null)}
                </Collapse>
            </div>
        );
    }
};

export default InsertPage
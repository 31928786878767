import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { search } from 'api/graphAPI'
import { AppThunk } from 'app/store'

interface SearchResultState {
    searchResults: any
    filter: string[]
    error: string | null
}

const initialState: SearchResultState = {
  searchResults: [],
  filter: [],
  error: null
}

const searchDetails = createSlice({
  name: 'searchDetails',
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<string[]>) {
      state.filter = action.payload
      state.error = null
    },
    getSearchResultsSuccess(state, action: PayloadAction<any>) {
      state.searchResults = action.payload
      state.error = null
    },
    getSearchResultsFailure(state, action: PayloadAction<string>) {
      state.searchResults = -1
      state.error = action.payload
    }
  }
})

export const {
  getSearchResultsSuccess,
  getSearchResultsFailure,
  setFilter
} = searchDetails.actions

export default searchDetails.reducer

export const fetchSearchResults = (
  query: string
): AppThunk => async (dispatch, getState) => {

  try {
    const searchResults: any[] = await search(query, getState().searchReducer.filter)
    console.log('searchResults', searchResults)
    let distinctResults = Array.from(new Set(searchResults))
    dispatch(getSearchResultsSuccess(distinctResults))
  } catch (err) {
    dispatch(getSearchResultsFailure(err.toString()))
  }
}